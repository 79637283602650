import {NgModule} from '@angular/core';
import {SisScrollable} from './scrollable/scrollable';
import {BidiModule} from '@angular/cdk/bidi';
import {PlatformModule} from '@angular/cdk/platform';
import {SisFixedSizeVirtualScroll} from './fixed-size-virtual-scroll/fixed-size-virtual-scroll';
import {SisVirtualScrollViewportComponent} from './virtual-scroll-viewport/sis-virtual-scroll-viewport.component';
import {SisVirtualForOf} from './virtual-for-of/virtual-for-of';
import {ScrollDispatcher} from './scroll-dispatcher/scroll-dispatcher';
import {ViewportRuler} from './viewport-ruler/viewport-ruler';

@NgModule({
	exports: [SisScrollable],
	declarations: [SisScrollable]
})
export class SisScrollableModule {}

/**
 * @docs-primary-export
 */
@NgModule({
	imports: [
		BidiModule,
		PlatformModule,
		SisScrollableModule
	],
	exports: [
		BidiModule,
		SisScrollableModule,
		SisFixedSizeVirtualScroll,
		SisVirtualForOf,
		SisVirtualScrollViewportComponent,
	],
	declarations: [
		SisFixedSizeVirtualScroll,
		SisVirtualForOf,
		SisVirtualScrollViewportComponent,
	],
	providers: [
		ScrollDispatcher,
		ViewportRuler
	]
})
export class ScrollingModule {}
