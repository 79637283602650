/**
 * Created by Razmik.Mkrtchyan on 9/24/2016.
 */

import {Component, OnInit, Renderer, Input} from "@angular/core";

import "./sis-error.component.css";  @Component({
    moduleId: module.id,
    selector: 'sis-error',
    templateUrl: "sis-error.component.html",
    inputs: ['id', 'forControl', 'errorClass', 'isInput']

})
/**
 * ValidationErrorMessage component for showing/hiding error messages
 * inputs: ['id', '_errorMessage', 'forControl', 'errorClass']
 * @id:string -id of component
 * @_errorMessage:string -message which should show
 * @forControl:( template reference type variable) -control for which must show message
 * @errorClass:string -class name which you want to add to @forControl on case of invalid data
 * Also on case of @isInput==true component added onfocus,onblur,onmouseover,onmouseout events to @forControl for
 * showing/hiding error message
 */
export class ValidationErrorMessage{

    private forControl: any;
    private _errorMessage: string;
    private errorClass: string = 'sis-error-container';

    @Input() id: string;

    @Input('errorMessage')
    public set errorMessage(errorMessage: string) {
        this._errorMessage = errorMessage;
        this._errorMessage && this.forControl.classList.add(this.errorClass);
        !this._errorMessage && this.forControl.classList.contains(this.errorClass) && this.forControl.classList.remove(this.errorClass);
    }

    public get errorMessage(): string {
        return this._errorMessage;
    }

    constructor() { }

    public hasError(): boolean {
        return !!this._errorMessage;
    }

}
