import {ElementRef, Inject, Injectable, NgZone, OnDestroy, Optional} from '@angular/core';
import {Platform} from '@angular/cdk/platform';
import {DOCUMENT} from '@angular/common';
import {Observable} from 'rxjs/Observable';
import {Observer} from 'rxjs/Observer';
import {Subject} from 'rxjs/Subject';
import {Subscription} from 'rxjs/Subscription';
import {SisScrollable} from '../scrollable/scrollable';
import {auditTime, filter} from 'rxjs/operators';
import {of} from 'rxjs/observable/of';
import {fromEvent} from 'rxjs/observable/fromEvent';

/** Time in ms to throttle the scrolling events by default. */
export const DEFAULT_SCROLL_TIME = 20;

/**
 * Service contained all registered Scrollable references and emits an event when any one of the
 * Scrollable references emit a scrolled event.
 */
@Injectable()
export class ScrollDispatcher implements OnDestroy {
	/** Used to reference correct document/window */
	protected _document: Document;

	constructor(private _ngZone: NgZone,
		    private _platform: Platform,
		    @Optional() @Inject(DOCUMENT) document: any) {
		this._document = document;
	}

	/** Subject for notifying that a registered scrollable reference element has been scrolled. */
	private _scrolled = new Subject<SisScrollable | void>();

	/** Keeps track of the global `scroll` and `resize` subscriptions. */
	_globalSubscription: Subscription | null = null;

	/** Keeps track of the amount of subscriptions to `scrolled`. Used for cleaning up afterwards. */
	private _scrolledCount = 0;

	/**
	 * Map of all the scrollable references that are registered with the service and their
	 * scroll event subscriptions.
	 */
	scrollContainers: Map<SisScrollable, Subscription> = new Map();

	/**
	 * Registers a scrollable instance with the service and listens for its scrolled events. When the
	 * scrollable is scrolled, the service emits the event to its scrolled observable.
	 * @param scrollable Scrollable instance to be registered.
	 */
	register(scrollable: SisScrollable): void {
		if (!this.scrollContainers.has(scrollable)) {
			this.scrollContainers.set(scrollable, scrollable.elementScrolled()
				.subscribe(() => this._scrolled.next(scrollable)));
		}
	}

	/**
	 * Deregisters a Scrollable reference and unsubscribes from its scroll event observable.
	 * @param scrollable Scrollable instance to be deregistered.
	 */
	deregister(scrollable: SisScrollable): void {
		const scrollableReference = this.scrollContainers.get(scrollable);

		if (scrollableReference) {
			scrollableReference.unsubscribe();
			this.scrollContainers.delete(scrollable);
		}
	}

	/**
	 * Returns an observable that emits an event whenever any of the registered Scrollable
	 * references (or window, document, or body) fire a scrolled event. Can provide a time in ms
	 * to override the default 'throttle' time.
	 *
	 * **Note:** in order to avoid hitting change detection for every scroll event,
	 * all of the events emitted from this stream will be run outside the Angular zone.
	 * If you need to update any data bindings as a result of a scroll event, you have
	 * to run the callback using `NgZone.run`.
	 */
	scrolled(auditTimeInMs: number = DEFAULT_SCROLL_TIME): Observable<SisScrollable|void> {
		if (!this._platform.isBrowser) {
			return of<void>();
		}

		return new Observable((observer: Observer<SisScrollable|void>) => {
			if (!this._globalSubscription) {
				this._addGlobalListener();
			}

			// In the case of a 0ms delay, use an observable without auditTime
			// since it does add a perceptible delay in processing overhead.
			const subscription = auditTimeInMs > 0 ?
				this._scrolled.pipe(auditTime(auditTimeInMs)).subscribe(observer) :
				this._scrolled.subscribe(observer);

			this._scrolledCount++;

			return () => {
				subscription.unsubscribe();
				this._scrolledCount--;

				if (!this._scrolledCount) {
					this._removeGlobalListener();
				}
			};
		});
	}

	ngOnDestroy() {
		this._removeGlobalListener();
		this.scrollContainers.forEach((_, container) => this.deregister(container));
		this._scrolled.complete();
	}

	/**
	 * Returns an observable that emits whenever any of the
	 * scrollable ancestors of an element are scrolled.
	 * @param elementRef Element whose ancestors to listen for.
	 * @param auditTimeInMs Time to throttle the scroll events.
	 */
	ancestorScrolled(elementRef: ElementRef, auditTimeInMs?: number): Observable<SisScrollable|void> {
		const ancestors = this.getAncestorScrollContainers(elementRef);

		return this.scrolled(auditTimeInMs).pipe(filter(target => {
			return !target || ancestors.indexOf(target) > -1;
		}));
	}

	/** Returns all registered Scrollables that contain the provided element. */
	getAncestorScrollContainers(elementRef: ElementRef): SisScrollable[] {
		const scrollingContainers: SisScrollable[] = [];

		this.scrollContainers.forEach((_subscription: Subscription, scrollable: SisScrollable) => {
			if (this._scrollableContainsElement(scrollable, elementRef)) {
				scrollingContainers.push(scrollable);
			}
		});

		return scrollingContainers;
	}

	/** Use defaultView of injected document if available or fallback to global window reference */
	private _getWindow(): Window {
		return this._document.defaultView || window;
	}

	/** Returns true if the element is contained within the provided Scrollable. */
	private _scrollableContainsElement(scrollable: SisScrollable, elementRef: ElementRef): boolean {
		let element: HTMLElement | null = elementRef.nativeElement;
		let scrollableElement = scrollable.getElementRef().nativeElement;

		// Traverse through the element parents until we reach null, checking if any of the elements
		// are the scrollable's element.
		do {
			if (element == scrollableElement) {
				return true;
			}
		} while (element = element!.parentElement);

		return false;
	}

	/** Sets up the global scroll listeners. */
	private _addGlobalListener() {
		this._globalSubscription = this._ngZone.runOutsideAngular(() => {
			const window = this._getWindow();
			return fromEvent(window.document, 'scroll')
				.subscribe(() => this._scrolled.next());
		});
	}

	/** Cleans up the global scroll listener. */
	private _removeGlobalListener() {
		if (this._globalSubscription) {
			this._globalSubscription.unsubscribe();
			this._globalSubscription = null;
		}
	}
}
