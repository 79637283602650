/** Time in ms to throttle the resize events by default. */
import {Inject, Injectable, NgZone, OnDestroy, Optional} from '@angular/core';
import {Platform} from '@angular/cdk/platform';
import {DOCUMENT} from '@angular/common';
import {auditTime} from 'rxjs/operators';
import {Observable} from 'rxjs/Observable';
import {Subject} from 'rxjs/Subject';

export const DEFAULT_RESIZE_TIME = 20;

/** Object that holds the scroll position of the viewport in each direction. */
export interface ViewportScrollPosition {
	top: number;
	left: number;
}

@Injectable()
export class ViewportRuler implements OnDestroy {
	/** Cached viewport dimensions. */
	private _viewportSize: {width: number; height: number};

	/** Stream of viewport change events. */
	private _change = new Subject<Event>();

	/** Event listener that will be used to handle the viewport change events. */
	private _changeListener = (event: Event) => {
		this._change.next(event);
	};

	/** Used to reference correct document/window */
	protected _document: Document;

	constructor(private _platform: Platform,
		    ngZone: NgZone,
		    @Optional() @Inject(DOCUMENT) document: any) {
		this._document = document;

		ngZone.runOutsideAngular(() => {
			if (_platform.isBrowser) {
				const window = this._getWindow();

				// Note that bind the events ourselves, rather than going through something like RxJS's
				// `fromEvent` so that we can ensure that they're bound outside of the NgZone.
				window.addEventListener('resize', this._changeListener);
				window.addEventListener('orientationchange', this._changeListener);
			}

			// We don't need to keep track of the subscription,
			// because we complete the `change` stream on destroy.
			this.change().subscribe(() => this._updateViewportSize());
		});
	}

	ngOnDestroy() {
		if (this._platform.isBrowser) {
			const window = this._getWindow();
			window.removeEventListener('resize', this._changeListener);
			window.removeEventListener('orientationchange', this._changeListener);
		}

		this._change.complete();
	}

	/** Returns the viewport's width and height. */
	getViewportSize(): Readonly<{width: number, height: number}> {
		if (!this._viewportSize) {
			this._updateViewportSize();
		}

		const output = {width: this._viewportSize.width, height: this._viewportSize.height};

		// If we're not on a browser, don't cache the size since it'll be mocked out anyway.
		if (!this._platform.isBrowser) {
			this._viewportSize = null!;
		}

		return output;
	}

	/** Gets a ClientRect for the viewport's bounds. */
	getViewportRect(): ClientRect {
		const scrollPosition = this.getViewportScrollPosition();
		const {width, height} = this.getViewportSize();

		return {
			top: scrollPosition.top,
			left: scrollPosition.left,
			bottom: scrollPosition.top + height,
			right: scrollPosition.left + width,
			height,
			width,
		};
	}

	/** Gets the (top, left) scroll position of the viewport. */
	getViewportScrollPosition(): ViewportScrollPosition {
		if (!this._platform.isBrowser) {
			return {top: 0, left: 0};
		}

		const document = this._document;
		const window = this._getWindow();
		const documentElement = document.documentElement!;
		const documentRect = documentElement.getBoundingClientRect();

		const top = -documentRect.top || document.body.scrollTop || window.scrollY ||
			documentElement.scrollTop || 0;

		const left = -documentRect.left || document.body.scrollLeft || window.scrollX ||
			documentElement.scrollLeft || 0;

		return {top, left};
	}

	/**
	 * Returns a stream that emits whenever the size of the viewport changes.
	 * @param throttleTime Time in milliseconds to throttle the stream.
	 */
	change(throttleTime: number = DEFAULT_RESIZE_TIME): Observable<Event> {
		return throttleTime > 0 ? this._change.pipe(auditTime(throttleTime)) : this._change;
	}

	/** Use defaultView of injected document if available or fallback to global window reference */
	private _getWindow(): Window {
		return this._document.defaultView || window;
	}

	/** Updates the cached viewport size. */
	private _updateViewportSize() {
		const window = this._getWindow();
		this._viewportSize = this._platform.isBrowser ?
			{width: window.innerWidth, height: window.innerHeight} :
			{width: 0, height: 0};
	}
}
